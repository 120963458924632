import { Avatar, Card, CardHeader, IconButton, Stack, Typography } from '@mui/material';
import * as React from 'react';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

const TransactionCards = ({ item }) => {

  return (
    <Card sx={{ backgroundColor: 'white', m: 0, p: 0, px: 1, border: 0, position: 'relative', boxShadow: 0 }}>
      <CardHeader
        sx={{ mt: 0.5, p: 0 }}
        action={
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
            <Typography align='left' variant='h3' component="h3" sx={{ fontWeight: '600', fontSize: '1.2em' }}  > {item.total} тг. </Typography>
            <Stack direction="column" justifyContent="center" alignItems="center" spacing={0}>
              <IconButton size='' aria-label="settings" sx={{ p: 0 }} >
                {item.typeCard === 0 ? <CreditCardOutlinedIcon fontSize="large" sx={{ color: item.color }} /> : <PaymentsOutlinedIcon fontSize="large" sx={{ color: item.color }} />}
              </IconButton>
              {item.typeCard === 0 ? <Typography variant="caption" display="block" sx={{ fontSize: '0.6em' }} > ****-4511 </Typography> :
                <Typography variant="caption" display="block" sx={{ fontSize: '0.6em' }} > 1020-1022 </Typography>}
            </Stack>
          </Stack>
        }
        avatar={
          <Avatar sx={{ color: '#27374D', border: 1, borderColor: item.color, backgroundColor: 'white', }} >
            {item.gas}
          </Avatar>
        }
        title={<>
          <Typography align='left' variant='h3' component="h3" sx={{ fontWeight: '600', fontSize: '1.2em' }}  > {item.title} </Typography>
          <Typography align='left' variant='h2' component="h2" sx={{ fontSize: '0.8em' }} > {item.caption} </Typography>
        </>}
      />
    </Card>
  )
}

export default TransactionCards