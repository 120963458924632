//import s from './MainPanelAdministration.module.css';
import React from 'react';
import { connect } from 'react-redux';
import HomePageMobile from './HomePageMobile';

class HomePageMobileContainer extends React.PureComponent {

    render() {
        return (<div style={{maxWidth: "412px", margin: "0 auto"}} >            
                <HomePageMobile 
                />           
        </div>)
    }
}

const mapStateToProps = (state) => {
    return {    
        
    }
};

export default connect(mapStateToProps, {  
})(HomePageMobileContainer)
