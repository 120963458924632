import { Avatar, Card, CardActionArea, CardHeader, Typography } from '@mui/material';
import * as React from 'react';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';

const InfoCard = ({ title, captions, logoCard }) => {
    const logoCardImg = [<NoteAddOutlinedIcon fontSize="large" />, <AccountBalanceWalletOutlinedIcon fontSize="large" />, <PendingActionsOutlinedIcon fontSize="large" />]
    const colorCard = ['#74B65F', '#3EB5F1', '#D24A43']
    return (
        <Card sx={{ backgroundColor: colorCard[logoCard], borderRadius: 3, position: 'relative', width: '100%' }}>
            <CardActionArea>
                <CardHeader
                    sx={{ p: 1 }}
                    avatar={
                        <Avatar sx={{ backgroundColor: 'transparent', m: 0 }}>
                            {logoCardImg[logoCard]}
                        </Avatar>
                    }
                    title={<>
                        <Typography align='left' variant='h2' component="h2" sx={{ fontSize: '1em', color: 'white' }}  > {title} </Typography>
                        <Typography align='left' variant='h3' component="h3" sx={{ fontSize: '1em', color: 'white' }}  > {captions} </Typography>
                    </>}
                />
            </CardActionArea>
        </Card>
    )
}

export default InfoCard