import { Container, Divider, Grid, Stack, Typography } from '@mui/material';
import React, { useState } from 'react';
import TabPage from '../Components/TabPage';
import back from '../Img/backgraund.jpg'
import Cards from '../Components/Cards';
import InfoCard from '../Components/InfoCard';
import TransactionCards from '../Components/TransactionCards';

const HomePageMobile = () => {

    return <Container maxWidth="100%" sx={{ mt: 8, p: 0 }}>
        <img src={back} width={'100%'} style={{ top: 0, left: 0, position: 'absolute', zIndex: '-10' }} alt='Личный кабинет' />

        <Typography color={'white'} textAlign={'center'} variant="caption" display="block" sx={{ mb: 0 }} gutterBottom>
            Лицевой счет
        </Typography>
        <Typography color={'white'} variant="h5" textAlign={'center'} gutterBottom>
            1 000 251,50 тг.
        </Typography>
        <Cards />
        <Stack direction="row" spacing={1}>
            <InfoCard title={'Новая'} captions={'Заявка'} logoCard={0} />
            <InfoCard title={'Карты'} captions={'Талоны'} logoCard={1} />
            <InfoCard title={'Все'} captions={'Заявки'} logoCard={2} />
        </Stack>
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ mt: 2 }}>
            <Typography color={'#27374D'} fontSize={'16px'} variant="h5" display="block" gutterBottom>
                Транзакции заправок
            </Typography>
            <Typography textAlign={'right'} fontSize={'16px'} variant="caption" display="block" sx={{ mb: 0, mt: 1, textDecoration: 'underline' }} fontStyle={'italic'} >
                См. ещё
            </Typography>
        </Stack>
        <Stack direction="column" spacing={1}>
            {[
                {title: 'АЗС №1', caption: '10:51 - 10 июня 2024г.', gas: '95', color: 'red', total: '15,24', typeCard: 0},
                {title: 'АЗС №2', caption: '10:51 - 10 июня 2024г.', gas: '92', color: 'green', total: '44', typeCard: 0},
                {title: 'АЗС №14', caption: '10:51 - 10 июня 2024г.', gas: 'ДТ', color: 'blue', total: '150', typeCard: 1},
                {title: 'АЗС №1', caption: '10:51 - 10 июня 2024г.', gas: '95', color: 'red', total: '11', typeCard: 0},
                {title: 'АЗС №5', caption: '10:51 - 10 июня 2024г.', gas: '95', color: 'red', total: '18', typeCard: 0},
                {title: 'АЗС №1', caption: '10:51 - 10 июня 2024г.', gas: 'ДТ', color: 'blue', total: '55,24', typeCard: 1}
                ].map((item)=> <Stack direction="column" spacing={1}> <TransactionCards item={item} /> <Divider /> </Stack>)}
        </Stack>
    </Container>
}

export default HomePageMobile;
