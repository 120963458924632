import { Avatar, Card, CardHeader, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

const Cards = () => {
    
    return (
        <Card sx={{ backgroundColor: 'white', m: 2, p:2, pb: 0,  borderRadius: 3, position: 'relative' }}>
            <CardHeader
                sx={{ p: 1, border: 1, borderRadius: 1 }}
                action={
                    <IconButton size='' aria-label="settings" sx={{ width: 56, height: 56 }} >
                      <CreditCardOutlinedIcon fontSize="large" sx={{color: 'red'}}/>
                    </IconButton>
                  }
                avatar={
                    <Avatar sx={{color: '#27374D', border: 1, borderColor: 'red', backgroundColor: 'white', }} >
                      95
                    </Avatar>
                  }
                title={<>
                    <Typography align='left' variant='body1' sx={{ fontSize: '0.8em' }} > Договор №4-10 от 15,02,2024г. </Typography>
                    <Typography align='left' variant='body1' sx={{ fontSize: '0.8em' }} > 5550 1122 2222 5555 </Typography>
                    <Typography align='left' variant='h6' component="h6" sx={{ fontWeight: '600', fontSize: '1.2em', lineHeight: 1 }}  > 500,05 </Typography>

                </>}
                // subheader={<>
                //     <Typography variant="caption" align='left' display="block" gutterBottom> <span style={{ fontWeight: '600', color: 'green', fontSize: '14px' }} >+20%</span> С прошлого месяца </Typography>
                // </>}

            />
            <CardHeader
                sx={{ mt: 0.5, p: 1, border: 1, borderRadius: 1 }}
                action={
                    <IconButton size='' aria-label="settings" sx={{ width: 56, height: 56 }} >
                      <PaymentsOutlinedIcon fontSize="large" sx={{color: 'blue'}} />
                    </IconButton>
                  }
                avatar={
                    <Avatar sx={{color: '#27374D', border: 1, borderColor: 'blue', backgroundColor: 'white', }} >
                      ДТ
                    </Avatar>
                  }
                title={<>
                    <Typography align='left' variant='body1' sx={{ fontSize: '0.8em' }} > Договор №4-10 от 15,02,2024г. </Typography>
                    <Typography align='left' variant='body1' sx={{ fontSize: '0.8em' }} > 123544 - 123594</Typography>
                    <Typography align='left' variant='h6' component="h6" sx={{ fontWeight: '600', fontSize: '1.2em', lineHeight: 1 }}  > 500,05 </Typography>

                </>}
                // subheader={<>
                //     <Typography variant="caption" align='left' display="block" gutterBottom> <span style={{ fontWeight: '600', color: 'green', fontSize: '14px' }} >+20%</span> С прошлого месяца </Typography>
                // </>}

            />
                <Typography textAlign={'right'} variant="caption" display="block" sx={{ mb: 0, mt: 1, textDecoration: 'underline' }} fontStyle={'italic'} >
                    Ещё 3+
                </Typography>
        </Card>
    )
}

export default Cards