import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import back from '../Img/backgraund.jpg';
import { Button, Divider, InputBase, MenuList, Stack } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import AccountBoxRoundedIcon from '@mui/icons-material/AccountBoxRounded';
import { useNavigate } from 'react-router-dom';
import AppsRoundedIcon from '@mui/icons-material/AppsRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { styled, alpha } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';

function AppBarSite() {

    const [anchorElOnlineBuh, setAnchorElOnlineBuh] = React.useState(null);
    const openOnlineBuh = Boolean(anchorElOnlineBuh);
    const handleClickOnlineBuh = (event) => {
        setAnchorElOnlineBuh(event.currentTarget);
    };
    const handleCloseOnlineBuh = () => {
        setAnchorElOnlineBuh(null);
    };

    const navigate = useNavigate();

    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const open = Boolean(anchorElUser);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseExitMenu = () => {
        setAnchorElUser(null);
    };

    const settings = [
        { title: 'Главная', onClick: () => { navigate('/'); handleCloseExitMenu() }, idMenu: 'none', idBTN: 'home' },
        { title: 'Аутсорсинг бухгалтерии', onClick: () => { navigate('/uslugi_buhgaltera'); handleCloseExitMenu() }, idMenu: 'none', idBTN: 'buh' },
        { title: 'Онлайн Бухгалтер', onClick: handleClickOnlineBuh, idMenu: "menu-onlineBuh", idBTN: 'OnlineBuh' },
        { title: 'Цены', onClick: () => { window.open('https://finup.kz/buh/price/') }, idMenu: 'none', idBTN: 'price' },
        { title: 'Контакты', onClick: () => { window.open('https://finup.kz/buh/about_us/') }, idMenu: 'none', idBTN: 'contact' },
        // { title: 'Платежи', onClick: handleOpenFormPayments }, 
        //{ title: 'Выход', onClick: () => {localStorage.clear(); window.location.reload(true); } }
    ]

    const onlineBuhMNU = [
        { title: 'Обзор сервиса', onClick: () => { navigate('/online_buhgalteriya'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Первые настройки', onClick: () => { navigate('/instrukciy_online_buhgalter'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Функции', onClick: () => { navigate('/functions_online_buhgalter'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Основные разделы', onClick: () => { navigate('/sections_online_buhgalter'); handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Подпись и печать', onClick: () => { handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Частые вопросы', onClick: () => { handleCloseExitMenu(); handleCloseOnlineBuh(); } },
        { title: 'Поддержка', onClick: () => { handleCloseExitMenu(); handleCloseOnlineBuh(); } },
    ]    

    return (
        <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }} top="0">
            <Container maxWidth="xs">
                <Toolbar disableGutters>
                    <IconButton variant="outlined" onClick={handleOpenUserMenu} sx={{ border: 1, borderRadius: 2, m: 1, p: 0.5, backgroundColor: '#27374D' }}>
                        <AppsRoundedIcon fontSize="1.1em" sx={{ color: '#9DB2BF' }} />
                    </IconButton>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box >
                        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
                            <Badge badgeContent={4} sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: "#adff2f"
                                }
                            }} >
                                <MailIcon sx={{ color: '#27374D' }} />
                            </Badge>
                        </IconButton>
                        <IconButton
                            size="large"
                            aria-label="show 17 new notifications"
                            color="inherit"
                        >
                            <Badge badgeContent={17} sx={{
                                "& .MuiBadge-badge": {
                                    backgroundColor: "#adff2f"
                                }
                            }} >
                                <NotificationsIcon sx={{ color: '#27374D' }} />
                            </Badge>
                        </IconButton>                       
                    </Box>                    
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default AppBarSite;